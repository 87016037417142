import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import Cookies from "universal-cookie"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import CookieModal from "../../components/CookieModal/CookieModal"
import constants from "../../constants"
import "./cookie-bottom.scss"

const CookieBottom = () => {
  const cookies = new Cookies()
  const location = useLocation()
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const getClass = () => {
    const decorator = isVisible ? "visible" : "hidden"
    return `cookie-bottom cookie-bottom__${decorator}`
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cookies.get(constants.GA_COOCKIE_KEY)) return
      setIsVisible(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [setIsVisible])

  const accept = () => {
    cookies.set(constants.GA_COOCKIE_KEY, "true", { path: "/" })
    cookies.set(constants.FB_PIXEL_COOCKIE_KEY, "true", { path: "/" })
    initializeAndTrack(location)
    setIsVisible(false)
  }

  const reject = () => {
    setIsVisible(false)
  }

  return (
    <div className={getClass()}>
      <CookieModal
        isVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
      <p>
        <Trans i18nKey="cookie:cookieBottomDesc">
          part1
          <Link to={t("routes:cookie")} className="decoration"></Link>
          <span className="decoration"></span>
        </Trans>
      </p>
      <div className="cookie-bottom--actions">
        <button
          className="btn cookie-bottom--actions__no_borders"
          onClick={() => setIsModalVisible(true)}
        >
          {t("cookie:cookieBottomBtn1")}
        </button>
        <div className="cookie-bottom--actions__confirm">
          <button className="btn" onClick={accept}>
            {t("cookie:cookieBottomBtn2")}
          </button>
          <button className="btn" onClick={reject}>
            {t("cookie:cookieBottomBtn3")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieBottom
