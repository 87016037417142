import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import Cookies from "universal-cookie"
import { navigate } from "gatsby"

import "./age-verify.scss"
import CookieBottom from "../../components/CookieBottom/CookieBottom"
import HeroImg from "../../components/HeroImg/HeroImg"
import { ADULT_KEY, REDIRECT_URL } from "../../constants"

const AgeVerify = props => {
  const [isAdult, setIsAdult] = useState(null)
  const { t, i18n } = useTranslation()
  const cookies = new Cookies()

  const setAdult = value => {
    const redirectURL = cookies.get(REDIRECT_URL) ?? t("routes:home")
    if (value) {
      cookies.set(ADULT_KEY, true)
      cookies.remove(REDIRECT_URL)
      navigate(redirectURL)
      return
    } else {
      cookies.set(ADULT_KEY, false)
    }
    setIsAdult(value)
  }

  const changeLang = lang => {
    i18n.changeLanguage(lang)
  }

  const getActiveClass = lang => {
    const className = "age-verify-lang-switcher-active"
    return lang === i18n.language ? className : ""
  }

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Gorda rakija | Destilerija Veljko i Sinovi"
        />
        <meta property="og:image" content="/icons/icon-512x512.png" />
      </Helmet>

      <div className="age-verify">
        <HeroImg
          customClass="age-verify-hero"
          backgroundImage="bg/age-verify-hero.jpg"
        >
          {
            <>
              <img
                src="/img/gorda-logo-with-text.svg"
                className="gorda-logo box"
                alt="logo"
              />
              <h1 className="age-verify-title box-two">
                {t("age-verify:welcome")}
              </h1>
              <p className="box">{t("age-verify:terms")}</p>
              <div className="age-verify-lang-switcher">
                {t("age-verify:selectLanguage")}
                <div>
                  <span
                    className={getActiveClass("sr")}
                    onClick={() => changeLang("sr")}
                  >
                    {t("age-verify:serbian")}
                  </span>
                  <span
                    className={getActiveClass("en")}
                    onClick={() => changeLang("en")}
                  >
                    {t("age-verify:english")}
                  </span>
                </div>
              </div>
              <div className="position-is-adult">
                <div className="btn-position box">
                  <button className="btn" onClick={() => setAdult(true)}>
                    {t("age-verify:yesIm")}
                  </button>

                  <button className="btn" onClick={() => setAdult(false)}>
                    {t("age-verify:noImNot")}
                  </button>
                </div>
                {isAdult === false && (
                  <p className="is-adult">{t("age-verify:terms")}</p>
                )}
              </div>
              <p className="bottom-text">
                <Trans i18nKey="age-verify:ageVerifyText">
                  part1
                  <Link to={t("routes:terms")} className="decoration"></Link>
                  <Link to={t("routes:cookie")} className="decoration"></Link>
                </Trans>
              </p>
              <CookieBottom />
            </>
          }
        </HeroImg>
      </div>
    </>
  )
}

export default AgeVerify
